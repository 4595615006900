import React, { useState } from "react";
import { styled } from "../../../../stitches.config";
import { h2 } from "../../../../redesign/commonStyles/Fonts";
import SubscribeButton from "../../../../redesign/SubscribeButton";
import CategoryInfoSign from "../../../../redesign/components/CategoryInfoSign";
import { DISABLE_PREMIUM_FLAG_ACTIVE } from "../../../../components/common/constants/constants"
import { withThemeContext } from "../../../../redesign/components/StitchesThemeProvider";

import { ReactComponent as MainStripes } from '../../../../redesign/assets/innpolandRedesign/main-stripes.svg?react';
import { ReactComponent as ThemeLogo } from '../../../../redesign/assets/innpolandRedesign/footer-inn-empty-gray-light.svg?react';
import GrayStripes from '../../../../redesign/assets/innpolandRedesign/gray-stripes.png';

interface HeadingProps {
  title: string;
  url?: string;
  btnSubscribe?: boolean;
  amount?: string;
  style?: string;
  subscribe?: boolean;
  specialHeading?: boolean;
  specialHeadingStyle?: "specialDots" | "fullDots" | "withoutDots";
  subscribeSection?: string;
  showMore?: boolean;
  infoSignText?: string;
  hiddenDots?: boolean;
  isDarkMode?: boolean;
}

const Heading: React.FC<HeadingProps> = ({
  title,
  url,
  btnSubscribe = false,
  amount,
  style = "",
  subscribe = false,
  specialHeading = false,
  specialHeadingStyle = "fullDots",
  subscribeSection = "",
  showMore = true,
  infoSignText,
  hiddenDots = false,
  isDarkMode,
  ...props
}) => {
  const [isActive, setActive] = useState(subscribe);
  //const [{ user }] = useAuth();

  const toggleClass = () => {
    setActive(!isActive);
  };

  const dotsStyle = specialHeadingStyle;

  let titleElement = <>{title}</>;
  if (url)
    titleElement = <a href={url}>{title}</a>;

  return (
    <div>
      <StyledHeading special={specialHeading} className={"style--" + style}>
        <StyledTitle className={h2() + ' heading-title'} special={specialHeading} dots={dotsStyle}>
          {dotsStyle == 'specialDots' ?
            <>
              <MainStripes />
              <span className={hiddenDots && dotsStyle == 'specialDots' ? "hidden-dots-span" : ""}>{titleElement}</span>
              <MainStripes />
            </>
            :
            <>
              {isDarkMode ?
                <ThemeLogo className="background-logo" />
                :
                <img src={GrayStripes} className="background-logo-light" />
              }
              <MainStripes className="background-logo--front" />
              <span className="background-logo--front">{titleElement}</span>
            </>
          }
          {!specialHeading && btnSubscribe && !DISABLE_PREMIUM_FLAG_ACTIVE && <SubscribeButton subscribed={subscribe} subscribeSection={subscribeSection} isToggleIcon />}
          {infoSignText && !DISABLE_PREMIUM_FLAG_ACTIVE && <CategoryInfoSign infoSignText={infoSignText} />}
        </StyledTitle>
      </StyledHeading>
    </div >
  );
};

const StyledHeading = styled("div", {
  width: "100%",
  display: "flex",
  variants: {
    special: {
      true: {
        alignItems: "center",
        "&.style--default-white": {
          color: "white",
          "span, a": {
            color: "white",
          }
        },
        "&.style--center-white": {
          color: "white",
          "span, a": {
            color: "white",
            textAlign: "center",
          }
        },
        "&.style--center-black": {
          color: "$grayscale100",
          "span, a": {
            color: "$grayscale100",
            textAlign: "center",
          }
        },
      },
      false: {
        justifyContent: "space-between",
        //margin: "0 0 25px",
        "@bp4": {
          //margin: "40px 0",
        }
      }
    }
  },
  // dodatkowe style dla Category, Category6
  "&.style--category-center-black": {
    "& .heading-title": {
      justifyContent: "flex-start",
      "@bp4": {
        justifyContent: "space-between",
        width: "100%",
      }
    },
    "span": {
      textAlign: "center",
      color: "$grayscale100",
    }
  },
  "&.style--category-center-white": {
    "& .heading-title": {
      justifyContent: "flex-start",
      "@bp4": {
        justifyContent: "space-between",
        width: "100%",
      }
    },
    "span": {
      textAlign: "center",
      color: "$grayscale0Fixed",
    }
  },
  "&.style--category-default-white": {
    "& .heading-title": {
      justifyContent: "flex-start",
    },
    "span": {
      color: "$grayscale0Fixed",
    }
  },
  "&.style--category-default-dark": {
    "& .heading-title": {
      justifyContent: "flex-start",
    },
  },
  // kategorie z kolorami 
  "&.style--innpoland-tech": {
    "svg": {
      ".st0inn": {
        fill: "#9500fe"
      }
    }
  },
  "&.style--innpoland-finanse": {
    "svg": {
      ".st0inn": {
        fill: "#ff7200"
      }
    }
  },
  "&.style--innpoland-praca": {
    "svg": {
      ".st0inn": {
        fill: "#ffe600"
      }
    }
  },
  "&.style--innpoland-eco": {
    "svg": {
      ".st0inn": {
        fill: "#01ff0d"
      }
    }
  },
  "&.style--innpoland-biznes": {
    "svg": {
      ".st0inn": {
        fill: "#0067ff"
      }
    }
  },
  "&.style--innpoland-magazyn": {
    "svg": {
      ".st0inn": {
        fill: "#ff04d0"
      }
    }
  },
  ".background-logo": {
    position: "absolute",
    bottom: "3px",
    left: "-5px",
    width: "222px",
    opacity: 0.3,
    "@bp4": {
      bottom: "18px",
      left: "-10px",
      width: "373px",
    },
  },
  ".background-logo-light": {
    position: "absolute",
    bottom: "3px",
    left: "-4px",
    width: "208px",
    "@bp4": {
      bottom: "18px",
      left: "-7px",
      width: "366px",
    },
  },
  ".background-logo--front": {
    position: "relative",
    zIndex: 2,
    marginTop: "80px",
  }
});

const StyledTitle = styled("div", {
  position: "relative",
  width: "100%",
  letterSpacing: "0px",
  display: "flex",
  "@bp4": {
    fontSize: "38px",
    lineHeight: "42px"
  },
  "& > span": {
    marginRight: "12px",
    color: "$grayscale100",
  },
  "a,a:focus,a:hover": {
    color: "inherit",
    textDecoration: "inherit"
  },
  variants: {
    special: {
      true: {
        padding: "0 0 12px",
        "& p": {
          width: "100%",
          textAlign: "center",
          margin: 0,
        },
        "& .hidden-dots-span": {
          width: "100%",
          textAlign: "center",
          display: "block",
        },
        "@bp4": {
          padding: "40px 0"
        }
      },
      false: {
        padding: "0 0 12px",
        margin: 0,
        paddingLeft: 0,
        justifyContent: "flex-start",
        alignItems: "center",
        "@bp4": {
          width: "auto",
          marginBottom: "3px",
          padding: "40px 0"
        },
      }
    },
    dots: {
      fullDots: {
        justifyContent: "flex-start"
      },
      specialDots: {
        justifyContent: "flex-start",
        "@bp3": {
          justifyContent: "space-between",
        },
      },
      withoutDots: {}
    },
  },
  defaultVariants: {
    special: false
  },
  "svg": {
    width: "24px",
    "@bp4": {
      width: "42px",
    },
    ".st0inn": {
      fill: "$primary",
    }
  }
});


export default withThemeContext(Heading);